<template>
	<div class="email-template-setting">
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Email Template</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end" v-if="false">
				<v-btn dense tile>
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<template v-if="emailTemplateContact.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Orders</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateContact" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateAsset.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Security Code</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateAsset" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateAssetStock.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Doing the Transactions</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateAssetStock" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateInventory.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Doing the Transactions</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateInventory" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateOrder.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Orders</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateOrder" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateCustomer.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Security Code</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateCustomer" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateReminder.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Reminders</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateReminder" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="emailTemplateTransaction.length > 0">
			<div class="border pb-3 mb-5">
				<v-layout class="py-2 px-2 blue lighten-5">
					<v-flex class="">
						<div class="fw-500 sub-heading primary--text text-capitalize">Doing the Transactions</div>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex class="d-flex justify-content-end"> </v-flex>
				</v-layout>
				<div class="pa-3 border-top" v-for="(row, index) in emailTemplateTransaction" :key="index">
					<div class="d-flex align-items-center py-2">
						<div class="fw-600 mr-5">{{ row.template }}</div>
						<div>
							<v-switch
								v-model="row.activated"
								inset
								hide-details
								class="my-0"
								color="green"
								v-on:change="updateStatusEmailTemplateSetting(row.id, $event)"
							></v-switch>
						</div>
					</div>
					<div class="d-flex align-items-center py-2">
						<div class="fw-500 mr-5">{{ row.description_text }}</div>
						<v-spacer></v-spacer>
						<div class="d-flex justify-content-end">
							<v-btn
								color="blue darken-4 white--text"
								tile
								depressed
								v-on:click="$router.push({ name: 'email-template-update', params: { id: row.id } })"
								>Edit</v-btn
							>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { GetEmailTemplates, updateEmailTemplateStatus } from "@/core/lib/setting.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import { filter } from "lodash";

export default {
	name: "EmailTemplate",
	data() {
		return {
			visit_switch: false,
			visit_switch1: false,
			emailTemplateContact: new Array(),
			emailTemplateAsset: new Array(),
			emailTemplateAssetStock: new Array(),
			emailTemplateInventory: new Array(),
			emailTemplateOrder: new Array(),
			emailTemplateCustomer: new Array(),
			emailTemplateReminder: new Array(),
			emailTemplateTransaction: new Array(),
		};
	},
	methods: {
		getEmailTemplate() {
			const _this = this;
			GetEmailTemplates()
				.then((data) => {
					_this.emailTemplateContact = filter(data, { group: "contact" });
					_this.emailTemplateAsset = filter(data, { group: "asset" });
					_this.emailTemplateAssetStock = filter(data, { group: "asset-stock" });
					_this.emailTemplateInventory = filter(data, { group: "inventory" });
					_this.emailTemplateOrder = filter(data, { group: "order" });
					_this.emailTemplateCustomer = filter(data, { group: "customer" });
					_this.emailTemplateReminder = filter(data, { group: "reminder" });
					_this.emailTemplateTransaction = filter(data, { group: "transaction" });
				})
				.catch((error) => {
					_this.goBack();
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateStatusEmailTemplateSetting(template, status) {
			const _this = this;
			updateEmailTemplateStatus(template, +!!status)
				.then(() => {
					_this.getEmailTemplate();
				})
				.catch((error) => {
					_this.goBack();
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getEmailTemplate();
	},
};
</script>
